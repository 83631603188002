import { NextJsImageProps } from 'interfaces'
import Image from 'next/image'
import { ImageWrapper } from './NextJsImage.style'

// For every prop expecting array value
// first value corresponds mobile and second to desktop

const NextJsImage: React.FunctionComponent<NextJsImageProps> = ({
  src,
  alt,
  width,
  height,
  styles = {},
  layout = 'intrinsic',
  objectFit = 'fill',
  isAbsoluteUrl = false,
}) => {
  return (
    <>
      <ImageWrapper height={height} width={width}>
        <Image
          src={isAbsoluteUrl ? src : `https:${src}`}
          width={width}
          height={height}
          alt={alt}
          style={styles}
          layout={layout}
          objectFit={objectFit}
        />
      </ImageWrapper>
    </>
  )
}

export default NextJsImage
