import { FlexBox, theme, trackEvent, TextField } from '@rario/shared-components'
import { SocialWidgetWrapper } from './JoinSocialCTA.styles'
import NextJsImage from 'components/NextJsImage'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'
import { getAppWebViewDataFromLocalStorage } from 'utils/utils'

const JoinSocialCTA: React.FunctionComponent<{
  socialCTAHeading: string
  socialCTADescription: string
  icon: string
  link: string
  packName?: string
  isHomePage?: boolean
}> = ({ socialCTAHeading, socialCTADescription, icon, link, isHomePage = false }) => {
  const mobileAppBaseConfigData = getAppWebViewDataFromLocalStorage()

  function onClickHandle() {
    const pageSource = !isHomePage ? 'homepage' : 'leagues'
    trackEvent('Section Interacted', 'click', {
      sub_category: `${pageSource}-social_media_share`,
      link_url: link,
      click_type: 'icon',
      social_media: socialCTAHeading,
      category: 'social_share',
      click_action: 'social_activity',
    })
    if (mobileAppBaseConfigData) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'share_url', url: link }))
    } else {
      window.open(link)
    }
  }

  return (
    <SocialWidgetWrapper onClick={onClickHandle}>
      {icon && (
        <NextJsImage
          src={icon}
          alt={socialCTAHeading}
          width={55}
          height={55}
          layout={'fixed'}
          objectFit={'contain'}
          isAbsoluteUrl={!isHomePage}
        />
      )}

      <FlexBox flexDirection={'column'} justifyContent={'center'} pl={'18px'} className="hover">
        <TextField
          color={PLATFORM_ORIGIN === ThemeEnum.CR ? theme.colors.whites[5] : theme.colors.white}
          fontWeight={500}
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'14px'}
          lineHeight={'17px'}
        >
          {socialCTAHeading}
        </TextField>
        <TextField fontWeight={400} fontSize={'12px'} lineHeight={'14px'}>
          <FlexBox maxWidth={'100%'}>{socialCTADescription}</FlexBox>
        </TextField>
      </FlexBox>
    </SocialWidgetWrapper>
  )
}

export default JoinSocialCTA
