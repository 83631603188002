import { customStyled, FlexBox, TextField, theme, ThemeType } from '@rario/shared-components'

export const HeaderWrapper = customStyled.header<{ theme?: ThemeType; top?: string; pt: number }>`
  padding-top: ${({ pt = 0 }) => pt}px;
  width: 100%;
  justify-content:center;
  z-index:99999;
  background:rgba(17, 21, 28, 0.3);
  display: flex;
  align-items: center;
  position: relative;

  & svg:hover path{
    opacity:1;
}
 & .menu-item:hover .dropdownMenu{
    display:block; 
 }
 & .menu-item:hover .icon{
  display:block;  

 }
& .menu-item:hover a{
  opacity:1;
}
.dropdown-menu{
  opacity:0.8;
}
& .dropdown-menu:hover{
 opacity:1;
}

`

export const ClippedBoxWrapper = customStyled(FlexBox)`
clip-path: polygon(0 0, 100% 0, 92.5% 100%, 0% 100%);
backdrop-filter:blur(20px);
`

export const LeftClippedBoxWrapper = customStyled(FlexBox)`
clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
`
export const RightClippedBoxWrapper = customStyled(FlexBox)`
clip-path: polygon(0 0, 86% 0, 100% 14%, 100% 100%, 0 100%, 0% 50%);
`
export const ClippedCtaWrapper = customStyled(FlexBox)`
clip-path: polygon(0 0, 90% 0, 100% 40%, 100% 100%, 0 100%, 0% 50%);
`
export const MenuFooterWrapper = customStyled(FlexBox)`
  border-radius: 5px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: -1;
    margin: -1px; 
    border-radius: inherit; 
    background: linear-gradient(127.59deg, ${theme.colors.secondary} -8.25%, rgba(154, 45, 41, 0) 113.95%);
  }
`
export const SignupButtonWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
}>`
background: ${({ theme }) => theme.colors.whites[3]};
border: 1px solid rgba(255, 255, 255, 0.1);
backdrop-filter: blur(13.5px);
border-radius: 5px;
`

export const TextFieldWrapper = customStyled(TextField)<{
  theme?: ThemeType
}>`
font-size:14px !important;
line-height:21px !important;
font-weight:600;
font-family:${({ theme }) => theme.fonts.primary};
letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
padding:9.5px 21px;
`

export const TextFieldGradient = customStyled(TextField)`
background: linear-gradient(180deg, #FFD360 0%, #FB8A1E 100%), linear-gradient(180deg, #FF4F44 0%, #DA2D22 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`

export const MarqueeItemWrapper = customStyled(FlexBox)<{ totalItems: number }>`
  animation: ${({ totalItems }) => `marquee ${totalItems * 0.8}s infinite linear`};

  @keyframes marquee {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: ${({ totalItems }) => `translateX(${(totalItems - 6) * -119}px)`};
    }
  }
`

export const StickyBar = customStyled.div<{
  theme?: ThemeType
}>`
z-index: 9999;
    position: fixed;
    bottom: -83px;
    cursor:pointer;
    transition: transform ease 1s;
    width: 100%;
    max-width: ${({ theme }) => `${theme.maxDesktopLayoutWidth}px`};
    left:50%;
    margin-left:  ${({ theme }) => `calc(min(100%,${theme.maxDesktopLayoutWidth}px) / -2)`};
    @media(max-width: ${({ theme }) => theme.breakpoints[0]}) {
        max-width:100%;
        margin-left:calc(100% / -2);
    }
  `
export const DreamSportsHeading = customStyled(TextField)`
   @media(max-width: 355px) {
     font-size: 16px;
   }
`

export const Dream11FFBottomWrapper = customStyled(FlexBox)`
  @media (max-width: 375px) {
    bottom: 5px;
  }
`

export const Dream11FFCTAWrapper = customStyled(FlexBox)`
  @media (max-width: 375px) {
    margin-top: 20px;
    grid-gap: 2px;
  }
`
export const SubHeadingWrapper = customStyled(TextField)`
  @media(max-width: 345px) {
    font-size: 10px;
  }
`
export const StepsTitleBox = customStyled(FlexBox)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  width: 100%;
  overflow: hidden;
`
