import { FlexBox, theme } from '@rario/shared-components'
// import AndroidIcon from 'assets/svg/AndroidIcon'
import JoinSocials from 'components/JoinSocials'
import { useCustomUser } from 'contexts/CustomUserProvider'
// import Link from 'next/link'
// import { useRouter } from 'next/router'
import React from 'react'
// import { apkDownloadHandler, isAndroid } from 'utils/utils'
import { RightClippedBoxWrapper } from './HomeLanding.styles'
import HomeLandingLoginFooter from './HomeLandingLoginFooter'
import HomeLandingSocialLinkFooter from './HomeLandingSocialLinkFooter'
// import { PLATFORM_ORIGIN } from 'config'
// import { ThemeEnum } from 'interfaces'

const HomeLandingFooter: React.FunctionComponent<{
  showSocialLinks?: boolean
  appDownloadLink?: string
  isDream11?: boolean
}> = ({ showSocialLinks }) => {
  const { user } = useCustomUser()
  return !user ? (
    <FlexBox flexDirection={'column'}>
      {showSocialLinks && (
        <>
          {/* {PLATFORM_ORIGIN === ThemeEnum.RARIO && (
            <RightClippedBoxWrapper
              background={theme.colors.white}
              height={'460px'}
              width={'100%'}
              flexDirection={'column'}
              px={'24px'}
              pt={'46px'}
            >
              <TextField
                fontSize={'24px'}
                lineHeight={'29.28px'}
                fontWeight={800}
                primaryHighlightColor={theme.colors.secondaryBG}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                color={theme.colors.black}
              >
                FOR THE **TRUE FAN!**
              </TextField>
              <TextField
                fontSize={'16px'}
                lineHeight={'24px'}
                fontWeight={400}
                color={theme.colors.black}
              >
                Start getting rewarded for your passion and knowledge of the game.
              </TextField>

              <FlexBox mt={'35px'}>
                {isAndroidDevice ? (
                  <Link href={'/app-download#installationSteps'} scroll={false}>
                    <Button
                      name={'GET THE APP'}
                      rightIcon={
                        <AndroidIcon
                          fill={isDream11 ? theme.colors.white : theme.colors.primaryText}
                        />
                      }
                      variant={'dynamic'}
                      fontSize={'16px'}
                      textTransform={'uppercase'}
                      onClick={() => {
                        apkDownloadHandler(
                          'home_footer-download_android_cta',
                          'GET THE APP',
                          appDownloadLink
                        )
                      }}
                      colorVariant={isDream11 ? 'greenFilled' : 'primaryFilled'}
                      primaryEdges={isDream11}
                      primaryEdgeColor={theme.colors.lightSecondary}
                      cornerIndentation={'17px'}
                    />
                  </Link>
                ) : (
                  <Button
                    name={'SIGNUP'}
                    variant={'dynamic'}
                    fontSize={'16px'}
                    textTransform={'uppercase'}
                    px="37px"
                    onClick={() => {
                      trackEvent('Section Interacted', 'click', {
                        sub_categroy: 'homepage-fan_section_sign_up',
                        type: 'cta',
                        category: 'homepage',
                        click_type: 'button',
                        click_text: 'SIGNUP',
                        click_action: 'click_signup_other_devices',
                      })
                      router.push('/signup')
                    }}
                    colorVariant={isDream11 ? 'greenFilled' : 'primaryFilled'}
                    primaryEdges={isDream11}
                    primaryEdgeColor={theme.colors.lightSecondary}
                    cornerIndentation={'15px'}
                  />
                )}
              </FlexBox>
            </RightClippedBoxWrapper>
          )} */}
          <RightClippedBoxWrapper
            width={'100%'}
            background={theme.colors.footerBG}
            flexDirection={'column'}
            pb={'40px'}
            mt={'0px'}
          >
            <JoinSocials title="JOIN OUR COMMUNITY" titleAlign="flex-start" />
          </RightClippedBoxWrapper>
        </>
      )}
      <HomeLandingSocialLinkFooter />
      <FlexBox flexDirection={'column'} mt={'10px'} mb={'10px'}>
        <HomeLandingLoginFooter />
      </FlexBox>
    </FlexBox>
  ) : (
    <FlexBox
      flexDirection={'column'}
      borderTop={`1px solid ${theme.colors.whites[3]}`}
      width={'95%'}
      mx={'10px'}
    >
      <HomeLandingLoginFooter />
    </FlexBox>
  )
}

export default HomeLandingFooter
