import { customStyled } from '@rario/shared-components'

export const SocialWidgetWrapper = customStyled.div`
    margin-top: 35px;
    margin-left: 25px;
    display: flex;
    cursor:pointer;
   &:hover {
        filter:brightness(1.1)!important;
        transform:scale(1.05);
        transition: transform .5s;
   }
    &:hover .hover > *{
        color:white;
        transition: color .5s;
    }
    &:not(:hover){
        transition:transform .5s;
    }
    &:not(:hover) .hover > *{
        transition:color .5s;
    }  
`
