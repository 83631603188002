import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const SocialWidgetsHeadingWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
  platform: string
}>`
    justify-content: flex-start;
    padding: 0 15px;
    margin: 60px 0px 25px 0px;
`

export const SocialWidgetsWrapper = customStyled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`
