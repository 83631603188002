import { FlexBox, TextField, theme } from '@rario/shared-components'
import JoinSocialCTA from 'components/JoinSocialCTA'
import { JoinSocialsProps } from 'interfaces'
import { getCDNUrl } from 'utils/utils'
import { SocialWidgetsWrapper } from './JoinSocials.styles'

const JoinSocials: React.FunctionComponent<JoinSocialsProps> = ({
  title,
  titleAlign = 'center',
  packName = '',
}) => {
  const socialWidgetsData = [
    {
      title: 'Telegram',
      subtitle: 'Make connections with members',
      icon: `${getCDNUrl('/images/social-telegram-icon-v2.png')}`,
      link: 'https://t.me/rariohq',
    },
    {
      title: 'Discord',
      subtitle: 'Warning: Things might get crazy',
      icon: `${getCDNUrl('/images/social-discord-icon-v2.png')}`,
      link: 'https://discord.gg/rario',
    },
    {
      title: 'Twitter',
      subtitle: 'Catch the latest buzz from Rario HQ',
      icon: `${getCDNUrl('/images/social-twitter-icon-v2.png')}`,
      link: 'https://www.twitter.com/rariohq',
    },
  ]

  return (
    <>
      <FlexBox
        mt={'20px'}
        px={'20px'}
        justifyContent={titleAlign === 'center' ? 'center' : 'flex-start'}
      >
        <TextField
          width={'150px'}
          fontWeight={700}
          fontSize={'20px'}
          lineHeight={'24.4px'}
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          textAlign={'left'}
        >
          {title}
        </TextField>
      </FlexBox>
      <FlexBox>
        <SocialWidgetsWrapper>
          {socialWidgetsData.map((item) => (
            <JoinSocialCTA
              socialCTAHeading={item.title}
              socialCTADescription={item.subtitle}
              icon={item.icon}
              link={item.link}
              packName={packName}
              key={item.title}
            />
          ))}
        </SocialWidgetsWrapper>
      </FlexBox>
    </>
  )
}

export default JoinSocials
