import { FlexBox, TextField, theme } from '@rario/shared-components'
import Logo from 'components/Logo'
import { HomeLandingFooterAlignType } from 'interfaces/rarioLanding'

const footerCountries = ['India', 'Singapore', 'UAE', 'Australia']

const HomeLandingLoginFooter: React.FunctionComponent<{ align?: HomeLandingFooterAlignType }> = ({
  align = 'center',
}) => {
  return (
    <FlexBox flexDirection="column">
      <FlexBox alignItems={align} flexDirection="column" mt={'40px'}>
        <Logo isBeta={false} width={'143'} height={'33'} />
        <FlexBox justifyContent={'center'} alignItems={'center'} mt={'7px'}>
          {footerCountries?.map((country, index) => {
            return (
              <FlexBox justifyContent={'center'} alignItems={'center'} key={`${country}-${index}`}>
                <TextField
                  mt={'2px'}
                  fontWeight={'400'}
                  fontSize={'10px'}
                  lineHeight={'11.22px'}
                  fontFamily={theme.fonts.secondary}
                  color={theme.colors.greyishWhite}
                  mr={'10px'}
                  p={0}
                  opacity={0.8}
                >
                  {country}
                </TextField>
                {index < footerCountries.length - 1 && (
                  <FlexBox position={'relative'} top={'-1.5px'}>
                    <TextField
                      position={'relative'}
                      color={theme.colors.greyishWhite}
                      fontSize={'10px'}
                      lineHeight={'11.22px'}
                      mr={'10px'}
                      p={0}
                      opacity={0.8}
                    >
                      .
                    </TextField>
                  </FlexBox>
                )}
              </FlexBox>
            )
          })}
        </FlexBox>

        <TextField
          mt={'12px'}
          fontWeight={'400'}
          fontSize={'10px'}
          lineHeight={'11.22px'}
          fontFamily={theme.fonts.secondary}
          opacity={0.5}
        >
          All rights reserved. © 2023 Digital Collectibles Pte. Ltd.
        </TextField>
        <TextField
          fontWeight={'400'}
          fontSize={'10px'}
          lineHeight={'11.22px'}
          fontFamily={theme.fonts.secondary}
          opacity={0.5}
        >
          © 2023 Rario Digital Private Limited.
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeLandingLoginFooter
